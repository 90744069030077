<template lang="pug">
article.product-card(
  :class="classes"
  @mouseenter="focus"
  @mouseleave="blur")

  .combined(data-placeholder v-if="!data")
    .frame
      a.preview
        .clp
      .info
        .product
          a.clp.w-7 &nbsp;
        .brand
          a.clp.w-4 &nbsp;
        .variant
          a.clp.w-7 &nbsp;
        .metas(v-if="isLogged")
          .unit-price.pf-nowrap
            .clp.mw-3 &nbsp;
          .units
            .clp.mw-2 &nbsp;

  .combined(v-else)
    .frame
      nuxt-link.preview(
        :to="getProductLink()"
        v-click="() => $amplitude.trackProduct(trackProps)")
        thumbnails-rotator(
          :items="thumbnailUrls"
          :playing="focusing")

      .overlay
        .tags
          .oos(v-if="isOutOfStock")
            span OUT OF STOCK
            nuxt-link.find-similar(
              v-if="isOutOfStock"
              :to="getProductLink({ hash: '#related-products' })"
              v-click="() => $amplitude.trackProduct(trackProps)")
              i.bx.bx-search
              span Find similar

          product-tag-stamp.tag(
            v-for="i, k in catalogTags"
            :key="k"
            :data="i")
          promotion-tag.promotions(
            v-if="hasPromotions"
            :type="promotionType"
            :product-id="data.product_id"
            show-popover)
          express-tag.express(
            v-if="data.has_warehoused_variant || data.is_warehoused_variant"
            v-tooltip="POD_EXPRESS_EXPLANATION")
          grow-tag.grow(
            v-else-if="data.has_pod_grow_product || data.is_pod_grow_variant"
            v-tooltip="POD_GROW_EXPLANATION")

        .actions(v-if="cartable")
          el-button.add-to-cart(
            v-if="cartable"
            type="primary"
            :disabled="adding"
            v-tooltip="addToCartTooltip"
            @click.prevent="addToCart")
            i.bx.bx-plus
            span.hidden-sm-and-down Add

      .info
        .product
          nuxt-link(
            :to="getProductLink()"
            v-text="data.product_name"
            :title="productCardType === PRODUCT_CARD_TYPES.RECOMMENDATION_CAROUSEL_ORDER_GUIDE ? data.product_name : ''"
            v-click="() => $amplitude.trackProduct(trackProps)")
        .brand
          span By&nbsp;
          nuxt-link.pf-ellipsis(
            :to="brandLink"
            :title="data.brand_name"
            v-text="data.brand_name"
            v-click="() => $amplitude.trackBrand(trackProps)")
        .variant(v-if="showVariant")
          nuxt-link.pf-ellipsis(
            :to="skuLink"
            :title="data.name"
            v-text="data.name"
            v-click="() => $amplitude.trackBrand(trackProps)")
        .metas(v-if="showMetas")
          .unit-price.pf-nowrap(v-if="data.unit_price_str && !isHeadBuyer")
            span {{ data.unit_price_str }}
            em / unit
          .units(v-if="data.variants_count")
            span {{ data.variants_count }}
            em(v-if="data.variants_count > 1") SKUs
            em(v-else) SKU
        slot

    .includes(v-if="isLogged")
      //- .metas__order-guide(v-if="type === 'order-guide'")
      .metas__recommended(v-if="type === 'recommended' && data.comment")
        .metas__recommended-comment.pf-ellipsis(
          v-if="data.comment"
          v-text="data.comment"
          v-tooltip="data.comment"
          v-ellipsis)

</template>

<script>
import ProductTagStamp from '~/components/stamps/ProductTagStamp'
import PromotionTag from '~/components/tags/PromotionTag'
import ExpressTag from '~/components/tags/ExpressTag'
import GrowTag from '~/components/tags/GrowTag'
import ThumbnailsRotator from '~/components/products/ThumbnailsRotator'

import { mapGetters } from 'vuex'
import { get, isEmpty } from 'lodash'
import { PRODUCT_CARD_TYPES, PRODUCT_AVAILABILITY, POD_EXPRESS_EXPLANATION, POD_GROW_EXPLANATION } from '~/settings'

export default {
  components: {
    ProductTagStamp,
    PromotionTag,
    ExpressTag,
    GrowTag,
    ThumbnailsRotator
  },

  inject: {
    selectSkuByImageUrl: { default: false }
  },

  props: {
    data: {
      type: Object,
      default: null
    },
    type: {
      type: String,
      default: ''
    },
    trackProps: {
      type: [Object, String],
      default: ''
    },
    imagePath: {
      type: String,
      default: null
    },
    showVariant: {
      type: Boolean,
      default: false
    },
    productCardType: {
      type: String,
      default: PRODUCT_CARD_TYPES.DEFAULT
    },
    isVariant: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      PRODUCT_CARD_TYPES,
      POD_EXPRESS_EXPLANATION,
      POD_GROW_EXPLANATION,
      intervalPreviewSku: null,
      settimeoutPreviewSku: null,
      adding: false,
      focusing: false,
      observer: null,
      observed: false
    }
  },

  computed: {
    ...mapGetters({
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer',
      isLogged: 'authentication/logged',
      isPreApprovalBuyer: 'authentication/isPreApprovalBuyer',
      trackProductImpressions: 'configs/trackProductImpressions'
    }),

    classes () {
      const type = {
        [PRODUCT_CARD_TYPES.RECOMMENDATION_CAROUSEL_HOME]: 'recommendation-carousel-home',
        [PRODUCT_CARD_TYPES.RECOMMENDATION_CAROUSEL_CATALOG]: 'recommendation-carousel-catalog',
        [PRODUCT_CARD_TYPES.RECOMMENDATION_CAROUSEL_ORDER_GUIDE]: 'recommendation-carousel-order-guide',
        [PRODUCT_CARD_TYPES.RECOMMENDATION_GRID]: 'recommendation-grid-card',
        [PRODUCT_CARD_TYPES.BRAND_DETAILS_GRID]: 'brand-details-grid-card',
        [PRODUCT_CARD_TYPES.DEFAULT]: 'default-card'
      }[this.productCardType]
      return {
        observed: this.observed,
        buyer: this.isBuyer,
        cartable: this.cartable,
        'head-buyer': this.isHeadBuyer,
        'out-of-stock': this.isOutOfStock,
        [type]: true
      }
    },

    isOutOfStock () {
      return this.isBuyer &&
        !this.isPreApprovalBuyer &&
        (
          this.data?.product_availability === PRODUCT_AVAILABILITY.SOLD_OUT ||
          this.data?.availability === PRODUCT_AVAILABILITY.SOLD_OUT
        )
    },

    cartable () {
      return !this.isOutOfStock &&
        this.isBuyer &&
        !this.isPreApprovalBuyer &&
        this.data?.cartable
    },

    hasPromotions () {
      return !!this.data?.promotion_types ||
        this.data?.promotions?.length > 0
    },

    showMetas () {
      if (this.$slots.default) return false
      return this.isHeadBuyer ||
        (this.isBuyer && !this.isPreApprovalBuyer)
    },

    catalogTags () {
      return this.data?.catalog_tags || []
    },

    promotionType () {
      const { promotionType } = this.$options.filters
      const types =
        this.data?.promotion_types ||
        this.data?.promotions?.map(i => i.promotion_type) ||
        []
      if (!types.length) return null
      return types.length > 1
        ? 'Promotions'
        : promotionType(types[0])
    },

    coverThumbnailUrl () {
      if (this.imagePath && get(this.data, this.imagePath))
        return get(this.data, this.imagePath)
      const { image_urls, image_url, product_variant_small_image_url, primary_image_url, photo_url } = this.data
      return image_urls?.thumbnail || image_url || product_variant_small_image_url || primary_image_url || photo_url
    },

    thumbnailUrls () {
      const img = get(this.data, this.imagePath)
      if (img) return [img]
      if (!Array.isArray(this.data.variant_image_urls) || isEmpty(this.data.variant_image_urls))
        return [this.coverThumbnailUrl]
      return this.data.variant_image_urls
        .map(s => s.photo_medium_url)
    },

    brandLink () {
      return this.$links
        .brand({
          id: this.data.brand_id,
          name: this.data.brand_name
        })
    },

    skuLink () {
      return this.$links
        .product({
          id: this.data.product_id,
          name: this.data.product_name,
          brand: this.data.brand_name,
          variant: this.data.name,
          variantId: this.data.id
        })
    },

    addToCartTooltip () {
      return this.isVariant
        ? 'Add 1 quantity to cart'
        : null
    }
  },

  watch: {
    data: {
      immediate: true,
      handler (to, from) {
        if (this.$isServer || !this.$el) return
        this.observe()
      }
    }
  },

  mounted () {
    this.observe()
  },

  beforeDestroy () {
    this.unobserve()
  },

  methods: {
    unobserve () {
      if (!this.observer) return
      this.observer?.unobserve(this.$el)
      this.observer = null
    },

    observe () {
      if (!this.trackProductImpressions) return
      if (this.observed || !this.data) return
      const options = {
        root: null,
        rootMargin: '16px',
        threshold: 1
      }
      this.observer = new IntersectionObserver(this.trackImpression, options)
      this.observer.observe(this.$el)
      this.observed = true
    },

    trackImpression (entries) {
      if (!this.data) return
      entries.forEach(entry => {
        if (entry.intersectionRatio === 1) {
          this.$amplitude.track('COUNT_PRODUCT_IMPRESSION', {
            ...this.trackProps,
            brand_id: this.data?.brand_id,
            product_id: this.data?.product_id,
            sku_id: this.data?.id,
            vendor_company_id: this.data?.vendor_company_id,
            product_type_id: this.data?.product_type_id
          })
          this.unobserve()
        }
      })
    },

    getProductLink (args = {}) {
      const query = {
        'q[tag_ids]': this.$route.query['q[tag_ids]'] || undefined
      }
      if (this.selectSkuByImageUrl) query.i = this.$links.hash(this.coverThumbnailUrl)
      return this.$links
        .product({
          id: this.data.product_id,
          name: this.data.product_name,
          brand: this.data.brand_name,
          query,
          ...args
        })
    },

    focus () {
      this.focusing = true
    },

    blur () {
      this.focusing = false
    },

    addToCart () {
      if (!this.isVariant) {
        this.$workflow.openProductPreview({
          parent: this,
          product: this.data
        })

        return
      }
      this.adding = true
      this.$api.catalog.getProductDetails(this.data.product_id)
        .then((res) => {
          this.$amplitude.track('CLICK_ADD_TO_CART', this.trackProps)
          return this.$workflow.addItemToCart({
            parent: this,
            product: res,
            variant: res.variants[this.data.id],
            quantity: 1,
            events: {
              close: () => {
                this.adding = false
              }
            }
          }).finally(() => {
            this.adding = false
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
article.product-card {
  $oos: #999;
  // &.observed {
  //   outline: 4px solid red;
  // }

  &.out-of-stock {
    .overlay {
      min-height: 160px;
      background: linear-gradient(180deg, $oos, transparent);
    }
    .combined {
      &:not([data-placeholder]) {
        &:hover {
          border-color: $oos;
        }
      }
    }
    &:not(:hover) {
      .preview {
        filter: grayscale(.95);
      }
    }
  }
  .combined {
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    height: 100%;
    overflow: hidden;
    border: 2px solid transparent;
    &:not([data-placeholder]) {
      &:hover {
        border-color: $--color-primary;
        .frame {
          .actions {
            display: block;
          }
          a {
            -webkit-line-clamp: inherit !important;
            line-clamp: inherit !important;
          }
        }
      }
    }
    > * {
      min-height: 0;
    }
    .frame {
      flex: 1;
    }
    .includes {
      font-size: 0;
      background: white;
      > * {
        font-size: 13px;
        padding: 14px 12px;
        border-top: 1px dashed #eee;
      }
    }
  }
  .frame {
    background: white;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    position: relative;
    gap: 8px;
    padding-top: 16px;
    padding-bottom: 120px;
  }
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px 8px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    pointer-events: none;
    > * {
      pointer-events: initial;
    }
    .oos {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      color: white;
      font-size: 12px;
      width: 100%;
      margin-bottom: 6px;
      > * {
        min-width: 0;
      }
      a {
        display: flex;
        align-items: center;
        i {
          font-size: 16px;
        }
        > * {
          min-width: 0;
        }
      }
    }
    .tags {
      display: flex;
      flex: 1;
      gap: 3px;
      flex-direction: column;
      align-items: flex-start;
    }
    .actions {
      display: none;
      .el-button {
        padding: 10px 14px;
        font-size: 13px;
        font-weight: $--font-weight-bold;
        border-radius: 1000px;
        box-shadow: 0 8px 16px 0 rgba($--color-primary, .25);
        i {
          vertical-align: middle;
          font-size: 20px;
        }
      }
    }
  }
  .preview {
    padding: 0 8px;
    aspect-ratio: .9;
    transition: filter .2s ease-in-out;
    .clp {
      width: 100%;
      aspect-ratio: 1;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 16px 12px 14px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0 0 6px 6px;
    background: linear-gradient(0deg, white 95%, transparent 100%);
    > * {
      min-height: 0;
    }
    .product {
      font-size: 14px;
      font-weight: $--font-weight-bold;
      line-height: 18px;
      min-height: 36px; // 2 lines
      a {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        overflow: hidden;
      }
    }
    .brand {
      font-size: 12px;
      display: flex;
      align-items: baseline;
      a {
        color: $--color-primary;
        flex: 1;
      }
      * {
        min-width: 0;
      }
    }
    .metas {
      margin-top: 4px;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      font-size: 16px;
      > * {
        min-width: 0;
      }
      em {
        font-style: normal;
        color: $--color-text-secondary;
        font-size: 12px;
        margin: 0 .5em;
      }
    }
  }
}
</style>
